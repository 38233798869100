import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Organization } from "../api/organization/organization";

const initialState: Organization = {
  name: "",
  address: "",
  country: "",
  state: "",
  city: "",
  region: "",
  metadata: {},
};

export const OrganizationReducer = createSlice({
  name: "organization",
  initialState,
  reducers: {
    organization: (state, action: PayloadAction<Organization>) =>
      action.payload,
  },
});

export const { organization } = OrganizationReducer.actions;
export default OrganizationReducer.reducer;
