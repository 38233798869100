// material-ui
// import { LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Lottie from "lottie-react";
import loaderAnimation from "../assets/Loader/loader.json";

// loader style
const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 2001,
  width: "100%",
  "& > * + *": {
    marginTop: theme.spacing(2),
  },
}));

// ==============================|| Loader ||============================== //

const Loader = () => (
  <LoaderWrapper>
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 9999,
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        animationData={loaderAnimation}
        style={{
          height: 200,
          width: 200,
        }}
      />
    </div>
    {/* <LinearProgress color="primary" /> */}
  </LoaderWrapper>
);

export default Loader;
