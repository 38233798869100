import { AppBar, Box, Link, Toolbar, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import Logo from "../../assets/Icons/svg/AmeyaLogoWhite.svg";
import { useAppSelector } from "../../reduxjs/hooks";
import CustomSnackbar from "../../components/custom/Snackbar";
import { Route } from "../../utils/Route";

export default function AuthLayout() {
  const notifyState = useAppSelector((state) => state.notify);
  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #3125A7 10.69%, #1F1769 100%)",
        height: "auto",
        minHeight: "100vh",
      }}
    >
      <CustomSnackbar notifyState={notifyState} />
      <AppBar
        position="static"
        sx={{ boxShadow: "none", backgroundColor: "transparent" }}
      >
        <Toolbar>
          <Box component="div" m={4}>
            <img src={Logo} alt="Ameya Logo" />
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="main">
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Outlet />
        </Box>
      </Box>

      <Box
        component={"footer"}
        sx={{
          width: "100%",
          height: "60px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "fixed",
          bottom: 0,
          color: "white",
          px: 4,
        }}
      >
        <Typography>© 2025 Ameya. All Rights Reserved.</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 4,
          }}
        >
          <Link sx={{ color: "white" }} target="_blank" href={Route.TERMSOFUSE}>
            Terms & Condition
          </Link>
          <Link
            sx={{ color: "white" }}
            target="_blank"
            href={Route.PRIVACYPOLICY}
          >
            Privacy Policy
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
