import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CohortPermission } from "../api/user/user";

const initialState: CohortPermission = {
  canListParticipant: false,
  canCreateParticipant: false,
  canUpdateParticipant: false,
  canDeleteParticipant: false,
  canListOrder: false,
  canCreateOrder: false,
  canUpdateOrder: false,
  canDeleteOrder: false,
  canListUser: false,
  canCreateUser: false,
  canUpdateUser: false,
  canDeleteUser: false,
  cohort: null,
};

export const CohortPermissionReducer = createSlice({
  name: "cohort-permission",
  initialState,
  reducers: {
    cohortPermission: (state, action: PayloadAction<CohortPermission>) =>
      action.payload,
  },
});

export const { cohortPermission } = CohortPermissionReducer.actions;
export default CohortPermissionReducer.reducer;
