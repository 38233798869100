export const Route = {
  WELCOME: "/welcome",
  VERIFY: "/verify",
  TERMSOFUSE: "/terms-of-use",
  PRIVACYPOLICY: "/privacy-policy",
  NOT_FOUND: "*",
  UNAUTHORIZED: "/unauthorized",
  DASHBOARD: "/dashboard",
  ORGANIZATION: "/organization",
  ASSESSMENT: "/assessment",
  LIST_ASSESSMENT: "/list-assessment",
  LIST_ORGANIZATION: "/list-organization",
  LIST_USER: "/list-user",
  LIST_COHORT: "/list-cohort",
  PARTICIPANT: "/participant",
  LIST_PARTICIPANT: "/list-participant",
  LIST_ORDER: "/list-order",
  COHORT: "/cohort",
  ORDER: "/order",
};
