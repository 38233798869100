import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TriggerProps {
  trigger: boolean;
}

const initialState: TriggerProps = {
  trigger: false,
};

export const TriggerReducer = createSlice({
  name: "trigger",
  initialState,
  reducers: {
    trigger: (state) => {
      console.log(state?.trigger);
      state.trigger = !state.trigger;
    },
  },
});

export const { trigger } = TriggerReducer.actions;
export default TriggerReducer.reducer;
