import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DrawerProps {
  open: boolean;
  id?: string;
}

const initialState: DrawerProps = {
  open: false,
};

export const DrawerReducer = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<DrawerProps>) => action.payload,
    closeDrawer: (state) => initialState,
  },
});

export const { openDrawer, closeDrawer } = DrawerReducer.actions;
export default DrawerReducer.reducer;
