import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRoles } from "../api/user/user";
import { Role } from "../utils/enums/roles.enum";

const initialState: UserRoles = {
  role: Role.ADMIN,
  canListParticipant: false,
  canCreateParticipant: false,
  canUpdateParticipant: false,
  canDeleteParticipant: false,
  canListOrder: false,
  canCreateOrder: false,
  canUpdateOrder: false,
  canDeleteOrder: false,
  canListUser: false,
  canCreateUser: false,
  canUpdateUser: false,
  canDeleteUser: false,
  organization: null,
};

export const PermissionReducer = createSlice({
  name: "user-permission",
  initialState,
  reducers: {
    userPermission: (state, action: PayloadAction<UserRoles>) => action.payload,
    platformOwnerAccess: (state) => {
      state.role = Role.PLATFORM_OWNER;
      state.canListParticipant = true;
      state.canCreateParticipant = true;
      state.canUpdateParticipant = true;
      state.canDeleteParticipant = true;
      state.canListOrder = true;
      state.canCreateOrder = true;
      state.canUpdateOrder = true;
      state.canDeleteOrder = true;
      state.canListUser = true;
      state.canCreateUser = true;
      state.canUpdateUser = true;
      state.canDeleteUser = true;
    },
  },
});

export const { userPermission, platformOwnerAccess } =
  PermissionReducer.actions;
export default PermissionReducer.reducer;
